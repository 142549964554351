//-----------------------------------------------------------------------------------
//
//  config.scss
//
//  1. Base Colors
//  2. Font Families
//  3. layouts
//  4. Breakpoint Setting
//
//-----------------------------------------------------------------------------------

//////////////////////////////////////////////////////////
// 1. Base Colors
//////////////////////////////////////////////////////////

$color-main:     red;
$color-sub:      green;
$color-white:     #f2f2f2;
$color-black:        #0F0F0F;


//////////////////////////////////////////////////////////
// 2. Font Families
//////////////////////////////////////////////////////////

@import url(https://fonts.googleapis.com/css2?family=Shippori+Mincho:wght@400;500;600;700;800&family=Source+Code+Pro:ital,wght@0,200..900;1,200..900&display=swap);

$font-body: "Source Code Pro", monospace;
$font-serif: "Shippori Mincho", 游明朝, "Yu Mincho", YuMincho, "Hiragino Mincho ProN", serif;


//////////////////////////////////////////////////////////
// 3. layouts
//////////////////////////////////////////////////////////

$c-width: 1100px;

//////////////////////////////////////////////////////////
// 3. Breakpoint Setting
//////////////////////////////////////////////////////////

$breakpoint-up: (
  'xxs': '(min-width: 360px)',
  'xs': '(min-width: 425px)',
  'sm': '(min-width: 554px)',
  'md': '(min-width: 768px)',
  'lg': '(min-width: 992px)',
  'xls': '(min-width: 1151px)',
  'xl': '(min-width: 1200px)',
) !default;

$breakpoint-down: (
  'xxs': '(max-width: 359px)',
  'xs': '(max-width: 424px)',
  'sm': '(max-width: 553px)',
  'md': '(max-width: 767px)',
  'lg': '(max-width: 991px)',
  'xl': '(max-width: 1199px)',
) !default;
