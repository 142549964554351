@charset "UTF-8";
@import "~sanitize.css";
@import url(https://fonts.googleapis.com/css2?family=Shippori+Mincho:wght@400;500;600;700;800&family=Source+Code+Pro:ital,wght@0,200..900;1,200..900&display=swap);
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

iframe {
  border: none !important;
}

:root {
  --c-all: #D9D9D9;
  --c-outdoor: #FE1515;
  --c-tools: #FEF615;
  --c-artworks: #A261FF;
  --c-maniac: #42E8F7;
  --c-meme: #00FFA5;
  --c-all-d: #abaaaa;
  --c-outdoor-d: #d91212;
  --c-tools-d: #cec811;
  --c-artworks-d: #8652d1;
  --c-maniac-d: #37c8d5;
  --c-meme-d: #04d88e;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: "Shippori Mincho", 游明朝, "Yu Mincho", YuMincho, "Hiragino Mincho ProN", serif;
  font-size: 14px;
  line-height: 1.6;
  color: #0F0F0F;
  background-color: #f2f2f2;
  -webkit-font-smoothing: antialiased !important;
}

::selection {
  background: red;
  color: #f2f2f2;
  text-shadow: none;
}

@media (min-width: 768px) {
  #app {
    max-width: 1100px;
    width: 100%;
    margin-inline: auto;
  }
}

.cat-3-6 ::selection {
  background: var(--c-meme);
  color: #0F0F0F;
  text-shadow: none;
}
.cat-3-6 .blog-entry a {
  text-decoration-color: var(--c-meme) !important;
}
.cat-3-6 .blog-entry a:hover {
  color: var(--c-meme) !important;
}
.cat-3-6 strong {
  background: var(--c-meme);
}

.cat-tools ::selection {
  background: var(--c-tools);
  color: #0F0F0F;
  text-shadow: none;
}
.cat-tools .blog-entry a {
  text-decoration-color: var(--c-tools) !important;
}
.cat-tools .blog-entry a:hover {
  color: var(--c-tools) !important;
}
.cat-tools strong {
  background: var(--c-tools);
}

.cat-maniac ::selection {
  background: var(--c-maniac);
  color: #0F0F0F;
  text-shadow: none;
}
.cat-maniac .blog-entry a {
  text-decoration-color: var(--c-maniac) !important;
}
.cat-maniac .blog-entry a:hover {
  color: var(--c-maniac) !important;
}
.cat-maniac strong {
  background: var(--c-maniac);
}

.cat-outdoor ::selection {
  background: var(--c-outdoor);
  color: #0F0F0F;
  text-shadow: none;
}
.cat-outdoor .blog-entry a {
  text-decoration-color: var(--c-outdoor) !important;
}
.cat-outdoor .blog-entry a:hover {
  color: var(--c-outdoor) !important;
}
.cat-outdoor strong {
  background: var(--c-outdoor);
}

.cat-graphic ::selection {
  background: var(--c-artworks);
  color: #0F0F0F;
  text-shadow: none;
}
.cat-graphic .blog-entry a {
  text-decoration-color: var(--c-artworks) !important;
}
.cat-graphic .blog-entry a:hover {
  color: var(--c-artworks) !important;
}
.cat-graphic strong {
  background: var(--c-artworks);
}

a {
  text-decoration: none;
  opacity: 1;
  transition: all 0.1s ease;
}
a img {
  border: none;
}

img {
  max-width: 100%;
}

.wp-embed-featured-image.square {
  margin-bottom: 0px !important;
}

iframe.wp-embedded-content {
  width: 100% !important;
}

.wp-embed-featured-image {
  margin-bottom: 0px !important;
}

.featherlight .featherlight-content {
  background: #000 !important;
}

.featherlight:last-of-type {
  background: rgba(0, 0, 0, 0.95) !important;
}

.featherlight .featherlight-close-icon {
  background: #fff;
  background: rgba(0, 0, 0, 0.95) !important;
  color: #fff !important;
}

#blog .blog-entry .blog-entry__body .img-auto {
  text-align: center !important;
}

@media (min-width: 768px) {
  #blog .blog-entry .blog-entry__body .img-auto img {
    margin: 0 auto !important;
    width: auto !important;
  }
}

.post-list {
  padding: 0 20px;
}
@media (min-width: 768px) {
  .post-list {
    padding: 0 10px;
  }
}
.post-list a {
  text-decoration: none;
  color: #0F0F0F;
  font-size: 100vw;
}
@media (min-width: 768px) {
  .post-list a {
    font-size: 1050px;
  }
}

.post-list-col {
  margin-bottom: 30px;
  position: relative;
}
@media (min-width: 768px) {
  .post-list-col {
    margin-bottom: 40px;
  }
  .post-list-col span:after {
    content: "";
    position: absolute;
    top: 0;
    left: -50vw;
    width: 150vw;
    display: inline-block;
    z-index: -1;
    height: 100%;
    opacity: 0;
    transition: all 0.4s ease-in;
  }
  .post-list-col:hover span:after {
    transition: all 0.01s ease-in;
  }
  .post-list-col.cat-outdoor:hover span:after {
    content: "";
    position: absolute;
    top: 0;
    left: -50vw;
    width: 150vw;
    display: inline-block;
    z-index: -1;
    height: 100%;
    opacity: 1;
    background-color: var(--c-outdoor);
  }
  .post-list-col.cat-tools:hover span:after {
    content: "";
    position: absolute;
    top: 0;
    left: -50vw;
    width: 150vw;
    display: inline-block;
    z-index: -1;
    height: 100%;
    opacity: 1;
    background-color: var(--c-tools);
  }
  .post-list-col.cat-graphic:hover span:after {
    content: "";
    position: absolute;
    top: 0;
    left: -50vw;
    width: 150vw;
    display: inline-block;
    z-index: -1;
    height: 100%;
    opacity: 1;
    background-color: var(--c-artworks);
  }
  .post-list-col.cat-maniac:hover span:after {
    content: "";
    position: absolute;
    top: 0;
    left: -50vw;
    width: 150vw;
    display: inline-block;
    z-index: -1;
    height: 100%;
    opacity: 1;
    background-color: var(--c-maniac);
  }
  .post-list-col.cat-3-6:hover span:after {
    content: "";
    position: absolute;
    top: 0;
    left: -50vw;
    width: 150vw;
    display: inline-block;
    z-index: -1;
    height: 100%;
    opacity: 1;
    background-color: var(--c-meme);
  }
}
.post-list-col .post-list-col__header {
  display: flex;
  font-family: "Source Code Pro", monospace;
  font-size: 12px;
  z-index: 1;
  font-weight: 600;
  margin-bottom: 5px;
}
@media (min-width: 768px) {
  .post-list-col .post-list-col__header {
    letter-spacing: 0.5px;
  }
}
.post-list-col .post-list-col__title {
  font-size: 35px;
  font-weight: 700;
  letter-spacing: -0.02em;
  font-feature-settings: "palt" 1 !important;
  line-height: 1.2;
}
@media (min-width: 768px) {
  .post-list-col .post-list-col__title {
    font-size: 55px;
    font-weight: 700;
    letter-spacing: -0.02em;
    line-height: 1.2;
  }
}
.post-list-col .post-list-col__title span {
  line-height: auto;
  mix-blend-mode: multiply;
}
.post-list-col [class^=c1] {
  font-size: 28.3333333333% !important;
  line-height: 1;
}
.post-list-col [class^=c2] {
  font-size: 28.3333333333% !important;
  line-height: 1;
  letter-spacing: -0.02em;
}
.post-list-col [class^=c3] {
  font-size: 28.3333333333% !important;
  line-height: 1;
}
.post-list-col [class^=c4] {
  font-size: 21.25% !important;
  line-height: 1;
}
.post-list-col [class^=c5] {
  font-size: 17% !important;
  line-height: 1;
}
.post-list-col [class^=c6] {
  font-size: 14.1666666667% !important;
  line-height: 1;
}
.post-list-col [class^=c7] {
  font-size: 12.1428571429% !important;
  line-height: 1;
}
.post-list-col [class^=c8] {
  font-size: 10.625% !important;
  line-height: 1;
}
.post-list-col [class^=c9] {
  font-size: 9.4444444444% !important;
  line-height: 1;
}
.post-list-col [class^=c10] {
  font-size: 35px !important;
  line-height: 1.4 !important;
}
@media (min-width: 768px) {
  .post-list-col [class^=c1] {
    font-size: 23.3333333333% !important;
    line-height: 1;
  }
  .post-list-col [class^=c2] {
    font-size: 14% !important;
    line-height: 1;
    letter-spacing: -0.02em;
  }
  .post-list-col [class^=c3] {
    font-size: 14% !important;
    line-height: 1;
  }
  .post-list-col [class^=c4] {
    font-size: 11.6666666667% !important;
    line-height: 1;
  }
  .post-list-col [class^=c5] {
    font-size: 14% !important;
    line-height: 1;
  }
  .post-list-col [class^=c6] {
    font-size: 10% !important;
    line-height: 1;
  }
  .post-list-col [class^=c7] {
    font-size: 8.75% !important;
    line-height: 1;
  }
  .post-list-col [class^=c8] {
    font-size: 8.75% !important;
    line-height: 1;
  }
  .post-list-col [class^=c9] {
    font-size: 7.7777777778% !important;
    line-height: 1;
  }
  .post-list-col [class^=c10] {
    font-size: 60px !important;
    line-height: 1.4 !important;
  }
}

.post-list-pager {
  padding: 20px 10px;
  font-size: 14px;
  text-align: center;
  font-family: "Shippori Mincho", 游明朝, "Yu Mincho", YuMincho, "Hiragino Mincho ProN", serif;
  margin-top: 35px;
  padding-bottom: 20px;
}
@media (min-width: 768px) {
  .post-list-pager {
    font-size: 20px;
    padding: 40px 10px;
    padding-bottom: 80px;
  }
}
.post-list-pager .pager {
  display: inline-block;
}
.post-list-pager .pager a {
  color: #0F0F0F;
}
.post-list-pager .pager a:hover {
  transform: scale(1.5);
}
.post-list-pager .pager .page-numbers {
  margin: 10px 5px;
  padding: 5px;
  display: inline-block;
}
@media (min-width: 768px) {
  .post-list-pager .pager .page-numbers {
    margin: 20px 20px 10px;
    padding: 10px 5px;
    display: inline-block;
  }
}
.post-list-pager .pager .page-numbers.current {
  border-bottom: 2px solid #000;
}
.post-list-pager .pager .next,
.post-list-pager .pager .prev {
  font-family: FontAwesome !important;
}

.blog-entry {
  padding: 0 4vw;
}
@media (min-width: 768px) {
  .blog-entry {
    padding: 20px;
  }
}
.blog-entry .blog-entry__title {
  font-size: 35px;
  font-weight: 700;
  letter-spacing: -1.8px;
  line-height: 1.4;
  padding: 0 0px;
}
@media (min-width: 768px) {
  .blog-entry .blog-entry__title {
    font-size: 45px;
    font-weight: 700;
    letter-spacing: -1px;
    line-height: 1.4;
    padding: 0 40px;
  }
}
.blog-entry .blog-entry__subtitle {
  font-family: "Source Code Pro", monospace;
  display: flex;
  font-weight: 600;
  justify-content: flex-end;
  font-size: 11px;
  margin-bottom: 10px;
  padding-right: 10px;
}
@media (min-width: 768px) {
  .blog-entry .blog-entry__subtitle {
    margin-bottom: 30px;
    padding-right: 40px;
  }
}
.blog-entry .blog-entry__subtitle a {
  color: #0F0F0F;
  text-decoration: none;
}
.blog-entry .blog-entry__subtitle .blog-entry__category {
  margin-left: 10px;
}
.blog-entry .blog-entry__body {
  margin-top: 30px;
  font-weight: 600;
}
@media (min-width: 768px) {
  .blog-entry .blog-entry__body {
    margin-top: 50px;
  }
}
.blog-entry .blog-entry__body p {
  font-size: 16.5px;
  font-feature-settings: "palt" 1;
  padding: 0;
  line-height: 32px;
  text-justify: inter-ideograph;
  word-break: break-word;
  word-wrap: break-word;
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  .blog-entry .blog-entry__body p {
    padding: 0 40px;
    line-height: 44px;
    font-size: 20px;
    margin-bottom: 20px;
    text-justify: inter-ideograph;
  }
}
.blog-entry .blog-entry__body a {
  color: #0F0F0F;
  text-decoration-line: underline;
  text-decoration-style: wavy;
  text-underline-offset: 5px;
}
.blog-entry .blog-entry__body cite {
  text-align: right;
  display: block;
  padding-bottom: 10px;
  font-family: "Source Code Pro", monospace;
}
.blog-entry .blog-entry__body figure {
  line-height: 33px;
  margin-bottom: 20px;
  padding: 0;
}
@media (min-width: 768px) {
  .blog-entry .blog-entry__body figure {
    padding: 0 40px;
    margin-bottom: 30px;
  }
}
.blog-entry .blog-entry__body figure.youtube {
  aspect-ratio: 16/9;
}
.blog-entry .blog-entry__body figure.youtube iframe {
  width: 100% !important;
  height: 100% !important;
}
.blog-entry .blog-entry__body figcaption {
  font-size: 12px;
  margin-top: 10px;
  margin-bottom: 30px;
  font-family: "Source Code Pro", monospace;
  font-weight: 400;
  line-height: 1.6;
  text-align: center;
  color: #0F0F0F;
}
@media (min-width: 768px) {
  .blog-entry .blog-entry__body figcaption {
    font-size: 14px;
    margin-top: 15px;
    line-height: 1.8;
    margin-bottom: 50px;
  }
}
.blog-entry .blog-entry__body img {
  width: calc(100% + 8vw) !important;
  max-width: calc(100% + 8vw) !important;
  height: auto;
  margin: 0 -4vw 0px -4vw;
}
@media (min-width: 768px) {
  .blog-entry .blog-entry__body img {
    width: calc(100% + 80px) !important;
    max-width: calc(100% + 80px) !important;
    height: auto;
    margin: 0 -40px 0px -40px !important;
  }
}
.blog-entry .blog-entry__body h1,
.blog-entry .blog-entry__body h2,
.blog-entry .blog-entry__body h3,
.blog-entry .blog-entry__body h4,
.blog-entry .blog-entry__body h5,
.blog-entry .blog-entry__body h6 {
  font-family: "Shippori Mincho", 游明朝, "Yu Mincho", YuMincho, "Hiragino Mincho ProN", serif;
  color: #0F0F0F;
  font-weight: 500;
  margin-bottom: 15px;
  margin-top: 40px;
  color: #0F0F0F;
}
.blog-entry .blog-entry__body h1, .blog-entry .blog-entry__body h2 {
  font-size: 22px;
  padding: 0 5px;
  margin: 30px 0 20px;
  border-bottom: 2px solid #000;
  border-top: 2px solid #000;
  display: inline-block;
  font-weight: 700;
  letter-spacing: -0.02em;
}
@media (min-width: 768px) {
  .blog-entry .blog-entry__body h1, .blog-entry .blog-entry__body h2 {
    font-size: 30px;
    margin: 40px 40px 30px 40px;
    color: #0F0F0F;
    padding: 5px 10px;
  }
}
.blog-entry .blog-entry__body h1 span, .blog-entry .blog-entry__body h2 span {
  color: #0F0F0F;
}
.blog-entry .blog-entry__body h1 + h2 {
  margin-top: 20px;
  display: block;
  width: fit-content;
}
.blog-entry .blog-entry__body h3 {
  font-size: 20px;
  padding: 0;
  font-weight: 700;
  letter-spacing: -0.02em;
}
@media (min-width: 768px) {
  .blog-entry .blog-entry__body h3 {
    font-size: 26px;
    padding: 0 40px;
  }
}
.blog-entry .blog-entry__body h4 {
  font-size: 18px;
  font-weight: bold;
  padding: 0;
  padding-left: 10px;
  font-weight: 700;
  letter-spacing: -0.02em;
  border-left: 3px solid #000;
}
@media (min-width: 768px) {
  .blog-entry .blog-entry__body h4 {
    font-size: 22px;
    border-left: 0 solid #000;
    padding: 0 40px;
  }
}
.blog-entry .blog-entry__body h5, .blog-entry .blog-entry__body h6 {
  font-size: 18px;
  font-weight: bold;
  padding: 0;
  font-weight: 700;
  letter-spacing: -0.02em;
}
@media (min-width: 768px) {
  .blog-entry .blog-entry__body h5, .blog-entry .blog-entry__body h6 {
    font-size: 22px;
    padding: 0 40px;
  }
}
.blog-entry .blog-entry__body strong {
  padding: 3px;
  vertical-align: top;
  margin: 0px 3px;
}
.blog-entry .blog-entry__body hr {
  display: block;
  width: 100%;
  height: 1px;
  background: #dcdcdc;
  margin: 30px auto;
  border: none;
}
@media (min-width: 768px) {
  .blog-entry .blog-entry__body hr {
    margin: 50px auto;
  }
}
.blog-entry .blog-entry__body blockquote {
  margin-left: -4vw;
  margin-right: -4vw;
  background-color: #f9f9f9;
  border-left: 4px solid #eaeaea;
  padding: 20px 35px;
  margin-bottom: 30px;
  font-style: italic;
}
@media (min-width: 768px) {
  .blog-entry .blog-entry__body blockquote {
    margin-left: 0;
    margin-right: 0;
    padding: 40px 70px;
    background-color: #f8f8f8;
  }
}
.blog-entry .blog-entry__body blockquote p {
  margin-bottom: 0;
  padding: 10px 0;
}
.blog-entry .blog-entry__body blockquote img {
  margin: 0 !important;
  width: auto !important;
  max-width: auto !important;
}
.blog-entry .blog-entry__body blockquote cite {
  font-family: "Shippori Mincho", 游明朝, "Yu Mincho", YuMincho, "Hiragino Mincho ProN", serif;
}
.blog-entry .blog-entry__body code,
.blog-entry .blog-entry__body pre {
  font-family: "Source Code Pro", Consolas, "Zen Kaku Gothic New", Menlo, Monaco, monospace;
}
.blog-entry .blog-entry__body code {
  font-size: 14px;
  font-family: "Source Code Pro", Consolas, "Zen Kaku Gothic New", Menlo, Monaco, monospace;
  margin: 0px 3px;
  padding: 2px 4px;
  border-radius: 4px;
  background-color: #d6d6d6;
  color: #0F0F0F;
}
@media (min-width: 768px) {
  .blog-entry .blog-entry__body code {
    padding: 5px 8px 5px;
    font-size: 16px;
    font-weight: 500;
  }
}
.blog-entry .blog-entry__body pre {
  width: calc(100% + 8vw);
  margin: 1.3em -4vw;
  padding: 20px 35px;
  border: none;
  background-color: #3a3a3a;
  color: #fff !important;
  font-size: 12px;
  font-family: "Source Code Pro", Consolas, Menlo, Monaco, monospace;
  line-height: 1.52;
  white-space: pre;
  overflow: auto;
}
@media (min-width: 768px) {
  .blog-entry .blog-entry__body pre {
    width: 100%;
    margin: 1.3em 0;
    padding: 40px 70px;
    font-size: 14px;
  }
}
.blog-entry .blog-entry__body pre code {
  padding: 0 !important;
  background: none !important;
  color: #f2f2f2;
}
.blog-entry .blog-entry__body code.hljs {
  background-color: #3a3a3a;
}
.blog-entry .blog-entry__body ul,
.blog-entry .blog-entry__body ol {
  list-style-position: inside;
  padding: 0 20px 20px;
}
@media (min-width: 768px) {
  .blog-entry .blog-entry__body ul,
  .blog-entry .blog-entry__body ol {
    padding: 0 60px 20px;
  }
}
.blog-entry .blog-entry__body ol {
  counter-reset: listnum;
}
.blog-entry .blog-entry__body ol li::before {
  counter-increment: listnum; /* counter-resetと同じ文字列 */
  content: counter(listnum) ". "; /* カウントした数に応じて番号を表示 */
}
.blog-entry .blog-entry__body ol li ul li::before {
  counter-increment: none !important;
  content: none !important;
}
.blog-entry .blog-entry__body ul {
  list-style-type: disc;
}
.blog-entry .blog-entry__body li {
  font-size: 16.5px;
  line-height: 1.6;
  margin-bottom: 15px;
  text-indent: -1em;
  padding-left: 1em;
  text-align: justify;
  text-justify: inter-ideograph;
}
@media (min-width: 768px) {
  .blog-entry .blog-entry__body li {
    text-align: left;
    font-size: 20px;
    margin-bottom: 10px;
    line-height: 40px;
    text-justify: inter-ideograph;
  }
}
.blog-entry .blog-entry__body li > ul,
.blog-entry .blog-entry__body li > ul > li {
  margin-bottom: 0 !important;
  padding-bottom: 0;
}
.blog-entry .blog-entry__body li ul {
  list-style-type: circle;
  padding-right: 0;
  padding-left: 2.5%;
}
.blog-entry .blog-entry__body li ul ul {
  list-style-type: disc;
  padding-right: 0;
  padding-left: 2.5%;
}
.blog-entry .blog-entry__body li ul ul ul {
  padding-right: 0;
  list-style-type: circle;
  padding-left: 2.5%;
}
.blog-entry .blog-entry__body sup {
  font-size: 60%;
  vertical-align: top;
  position: relative;
  top: -0.1em;
}
@media (min-width: 768px) {
  .blog-entry .blog-entry__body sup {
    top: -0.3em;
  }
}
.blog-entry .blog-entry__body sup::before {
  content: "[";
}
.blog-entry .blog-entry__body sup::after {
  content: "]";
}
.blog-entry .blog-entry__body sup a {
  vertical-align: top;
}
.blog-entry .blog-entry__body .footnotes, .blog-entry .blog-entry__body .wp-block-footnotes {
  margin-top: 20px;
}
.blog-entry .blog-entry__body .footnotes li, .blog-entry .blog-entry__body .wp-block-footnotes li {
  font-size: 14px;
  line-height: 1.8;
  margin-bottom: 5px;
}

#blog .blog-entry .blog-entry__body .amazlet-box img {
  width: auto !important;
  max-width: 100% !important;
  margin: 0 !important;
  padding-left: 10px;
}

.amazlet-box,
.amachazl-box {
  padding: 20px;
}

.nivo-lightbox-theme-default.nivo-lightbox-overlay {
  background: rgba(0, 0, 0, 0) !important;
}

.blog-entry-nav-wrap {
  padding-top: 35px;
  text-align: center;
}
.blog-entry-nav-wrap .blog-entry-nav {
  display: inline-block;
  position: relative;
  width: 70%;
  min-height: 44px;
  padding-bottom: 35px;
  font-family: "Shippori Mincho", 游明朝, "Yu Mincho", YuMincho, "Hiragino Mincho ProN", serif;
}
@media (min-width: 768px) {
  .blog-entry-nav-wrap .blog-entry-nav {
    width: 600px;
    padding-top: 70px;
    padding-bottom: 50px;
  }
}
.blog-entry-nav-wrap .blog-entry-nav .blog-entry-nav__menu {
  position: absolute;
  display: inline-block;
  width: 100px;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 0;
  font-weight: 500;
  font-size: 16px;
}
@media (min-width: 768px) {
  .blog-entry-nav-wrap .blog-entry-nav .blog-entry-nav__menu {
    font-size: 20px;
  }
}
.blog-entry-nav-wrap .blog-entry-nav .blog-entry-nav__menu a {
  color: #0F0F0F;
  transition: all 0.2s ease;
}
.blog-entry-nav-wrap .blog-entry-nav .blog-entry-nav__menu a:hover {
  opacity: 0.5;
}
.blog-entry-nav-wrap .blog-entry-nav .blog-entry-nav__prev {
  float: left;
  font-size: 16px;
  z-index: 100;
}
@media (min-width: 768px) {
  .blog-entry-nav-wrap .blog-entry-nav .blog-entry-nav__prev {
    font-size: 20px;
  }
}
.blog-entry-nav-wrap .blog-entry-nav .blog-entry-nav__prev a {
  color: #0F0F0F;
  transition: all 0.2s ease;
  padding-left: 10px;
  display: block;
}
.blog-entry-nav-wrap .blog-entry-nav .blog-entry-nav__prev a:hover {
  padding-left: 0px;
  padding-right: 10px;
}
.blog-entry-nav-wrap .blog-entry-nav .blog-entry-nav__next {
  float: right;
  font-size: 16px;
  z-index: 100;
}
@media (min-width: 768px) {
  .blog-entry-nav-wrap .blog-entry-nav .blog-entry-nav__next {
    font-size: 20px;
  }
}
.blog-entry-nav-wrap .blog-entry-nav .blog-entry-nav__next a {
  color: #0F0F0F;
  display: block;
  transition: all 0.2s ease;
  padding-right: 10px;
}
.blog-entry-nav-wrap .blog-entry-nav .blog-entry-nav__next a:hover {
  padding-right: 0px;
  padding-left: 10px;
}

#header {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  flex-direction: column;
  position: relative;
}
@media (min-width: 768px) {
  #header {
    flex-direction: row;
    padding: 20px 0px;
  }
}
#header h1 {
  font-family: "Source Code Pro", monospace;
  font-weight: 600;
  line-height: 1;
  letter-spacing: 1.4px;
  font-size: 12px;
  z-index: 10;
  position: absolute;
  top: 26px;
  left: 10px;
  padding: 5px;
}
@media (min-width: 768px) {
  #header h1 {
    position: relative;
    font-size: 14px;
    background: none;
    padding: 0;
    text-align: left;
  }
}
#header h1 a {
  text-decoration: none;
  color: #0F0F0F;
}
#header .postnav {
  margin-bottom: -30px;
}
@media (min-width: 768px) {
  #header .postnav {
    margin-bottom: 0;
  }
}
#header .postnav ul {
  display: flex;
  justify-content: flex-end;
}
@media (min-width: 768px) {
  #header .postnav ul {
    padding-right: 50px;
  }
}
#header .postnav ul li {
  font-size: 0;
  width: 15px;
  height: 120px;
  display: block;
  mix-blend-mode: multiply;
}
@media (min-width: 768px) {
  #header .postnav ul li {
    height: 100px;
    margin-left: 20px;
    transform: translateY(-70px);
  }
}
#header .postnav ul li.post-all a {
  background-color: var(--c-all);
}
#header .postnav ul li.post-outdoor a {
  background-color: var(--c-outdoor);
}
#header .postnav ul li.post-outdoor a:after {
  border: 0px solid var(--c-outdoor-d);
}
#header .postnav ul li.post-tools a {
  background-color: var(--c-tools);
}
#header .postnav ul li.post-tools a:after {
  border: 0px solid var(--c-tools-d);
}
#header .postnav ul li.post-graphic a {
  background-color: var(--c-artworks);
}
#header .postnav ul li.post-graphic a:after {
  border: 0px solid var(--c-artworks-d);
}
#header .postnav ul li.post-maniac a {
  background-color: var(--c-maniac);
}
#header .postnav ul li.post-maniac a:after {
  border: 0px solid var(--c-maniac-d);
}
#header .postnav ul li.post-3-6 a {
  background-color: var(--c-meme);
}
#header .postnav ul li.post-3-6 a:after {
  border: 0px solid var(--c-meme-d);
}
#header .postnav ul li a {
  background-color: gray;
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
}
#header .postnav ul li a:after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 0px;
  height: 0px;
  border: 0px solid #333;
  border-bottom-color: #fff !important;
  border-right-color: #fff !important;
  background-color: #fff !important;
  transition: all 0.1s ease;
}
#header .postnav ul li a:hover {
  transition: all 0.1s ease-in;
  margin-top: -5px;
}
#header .postnav ul li a:hover:after {
  transform: scale(1.1);
  border-width: 5px;
  filter: blur(0.2px);
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  #header .postnav ul li a:hover:after {
    filter: blur(0px) !important;
  }
}

.footer {
  position: relative;
  float: none;
  padding-top: 10px;
  top: auto;
  right: auto;
  clear: both;
}
@media (min-width: 768px) {
  .footer {
    position: fixed;
    height: 100%;
    float: left;
    right: 0px;
    top: 0px;
    padding-top: 130px;
    z-index: 9999;
  }
}
.footer p {
  width: 100%;
  position: relative;
  bottom: auto;
  right: auto;
  text-align: center;
  padding-bottom: 20px;
  font-family: "Source Code Pro", monospace;
  font-size: 10px;
  letter-spacing: 0.05em;
}
@media (min-width: 768px) {
  .footer p {
    position: absolute;
    width: 400px;
    bottom: 140px;
    right: -192px;
    margin: 0px !important;
    transform: rotate(-90deg);
  }
}
.footer p a {
  display: inline-block;
  color: #000;
}
.footer p a:hover {
  background: #d9d9d9;
}

.about-contents {
  padding: 0 4vw;
}
.about-contents h1 {
  font-size: 28px;
  font-family: "Shippori Mincho", 游明朝, "Yu Mincho", YuMincho, "Hiragino Mincho ProN", serif;
  letter-spacing: -0.02em;
  font-weight: 700;
  line-height: 1.3;
}
@media (min-width: 768px) {
  .about-contents h1 {
    font-size: 50px;
    margin-bottom: 40px;
  }
}
.about-contents p.prof {
  margin-top: 20px;
  display: block;
  font-weight: 700;
  font-size: 14px;
}
@media (min-width: 768px) {
  .about-contents p.prof {
    width: 50%;
    font-size: 15px;
  }
}
.about-contents .about-photo {
  margin-top: 40px;
  padding-bottom: 40px;
}
@media (min-width: 768px) {
  .about-contents .about-photo {
    width: 50%;
  }
}