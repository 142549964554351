:root {
  --c-all: #D9D9D9;
  --c-outdoor: #FE1515;
  --c-tools: #FEF615;
  --c-artworks: #A261FF;
  --c-maniac: #42E8F7;
  --c-meme: #00FFA5;

  --c-all-d: #abaaaa;
  --c-outdoor-d: #d91212;
  --c-tools-d: #cec811;
  --c-artworks-d: #8652d1;
  --c-maniac-d: #37c8d5;
  --c-meme-d: #04d88e;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: $font-serif;
  font-size: 14px;
  line-height: 1.6;
  color: $color-black;
  background-color: $color-white;
  -webkit-font-smoothing: antialiased !important;
}

::selection {
  background: $color-main;
  color: $color-white;
  text-shadow: none;
}



#app {
  @include media-up(md) {
    max-width: $c-width;
    width: 100%;
    margin-inline: auto;
  }
}


.cat-3-6 {
  ::selection {
    background: var(--c-meme);
    color: $color-black;
    text-shadow: none;
  }

  .blog-entry a {
    text-decoration-color: var(--c-meme) !important;

    &:hover {
      color: var(--c-meme) !important;
    }
  }

  strong {
    background: var(--c-meme);
  }
}

.cat-tools {
  ::selection {
    background: var(--c-tools);
    color: $color-black;
    text-shadow: none;
  }

  .blog-entry a {
    text-decoration-color: var(--c-tools) !important;

    &:hover {
      color: var(--c-tools) !important;
    }
  }


  strong {
    background: var(--c-tools);
  }
}

.cat-maniac {
  ::selection {
    background: var(--c-maniac);
    color: $color-black;
    text-shadow: none;
  }

  .blog-entry a {
    text-decoration-color: var(--c-maniac) !important;

    &:hover {
      color: var(--c-maniac) !important;
    }
  }

  strong {
    background: var(--c-maniac);
  }
}

.cat-outdoor {
  ::selection {
    background: var(--c-outdoor);
    color: $color-black;
    text-shadow: none;
  }

  .blog-entry a {
    text-decoration-color: var(--c-outdoor) !important;

    &:hover {
      color: var(--c-outdoor) !important;
    }
  }

  strong {
    background: var(--c-outdoor);
  }
}

.cat-graphic {
  ::selection {
    background: var(--c-artworks);
    color: $color-black;
    text-shadow: none;
  }

  .blog-entry a {
    text-decoration-color: var(--c-artworks) !important;

    &:hover {
      color: var(--c-artworks) !important;
    }
  }

  strong {
    background: var(--c-artworks);
  }
}


a {
  text-decoration: none;
  opacity: 1;
  transition: all .1s ease;

  img {
    border: none;
  }
}

img {
  max-width: 100%;
}

.wp-embed-featured-image.square {
  margin-bottom: 0px !important;
}

iframe.wp-embedded-content {
  width: 100% !important;
}

.wp-embed-featured-image {
  margin-bottom: 0px !important;
}

.featherlight .featherlight-content {
  background: #000 !important;
}

.featherlight:last-of-type {
  background: rgba(0, 0, 0, 0.95) !important;
}

.featherlight .featherlight-close-icon {
  background: #fff;
  background: rgba(0, 0, 0, 0.95) !important;
  color: #fff !important;
}

#blog .blog-entry .blog-entry__body .img-auto {
  text-align: center !important;
}

#blog .blog-entry .blog-entry__body .img-auto img {
  @include media-up(md) {
    margin: 0 auto !important;
    width: auto !important;
  }
}
