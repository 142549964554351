.about-contents {
  padding: 0 4vw;

  h1 {
    font-size: 28px;
    font-family: $font-serif;
    letter-spacing: -0.02em;
    font-weight: 700;
    line-height: 1.3;

    @include media-up(md){
      font-size: 50px;
      margin-bottom: 40px;
    }
  }

  p.prof {
    margin-top: 20px;
    display: block;
    font-weight: 700;
    font-size: 14px;

    @include media-up(md){
      width: 50%;
      font-size: 15px;
    }
  }

  .about-photo {
    margin-top: 40px;
    padding-bottom: 40px;

    @include media-up(md){
      width: 50%;
    }
  }
}

