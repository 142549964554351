.footer {
  position: relative;
  float: none;
  padding-top: 10px;
  top: auto;
  right: auto;
  clear: both;
  @include media-up(md){
    position: fixed;
    height: 100%;
    float: left;
    right: 0px;
    top: 0px;
    padding-top: 130px;
    z-index: 9999;
  }

	p {
    width: 100%;
    position: relative;
    bottom: auto;
    right: auto;
    text-align: center;
    padding-bottom: 20px;
    font-family: $font-body;
    font-size: 10px;
    letter-spacing: 0.05em;
    @include media-up(md){
      position: absolute;
      width: 400px;
      bottom: 140px;
      right: -192px;
      margin: 0px !important;
      transform: rotate(-90deg);
    }
    a {
      // text-decoration: underline;
      display: inline-block;
      color: #000;

      &:hover {
        background: #d9d9d9;
        // text-decoration: none;

      }
    }
	}
}
