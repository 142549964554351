#header {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  flex-direction: column;
  position: relative;

  @include media-up(md) {
    flex-direction: row;
    padding: 20px 0px;
  }

  h1 {
    font-family: $font-body;
    font-weight: 600;
    line-height: 1;
    letter-spacing: 1.4px;
    font-size: 12px;
    z-index: 10;
    position: absolute;
    top: 26px;
    left: 10px;
    // right:0;
    // margin: auto;
    // text-align: center;
    padding: 5px;
    // width: fit-content;
    // background-color: #fff;

    @include media-up(md) {
      position: relative;
      font-size: 14px;
      background: none;
      padding: 0;
      text-align: left;
    }

    a {
      text-decoration: none;
      color: $color-black
    }
  }

  .postnav {
    margin-bottom: -30px;

    @include media-up(md) {
      margin-bottom: 0;
    }

    ul {
      display: flex;
      justify-content: flex-end;

      @include media-up(md) {
        padding-right: 50px;
      }

      li {
        font-size: 0;
        width: 15px;
        height: 120px;
        display: block;
        mix-blend-mode: multiply;

        @include media-up(md) {
          height: 100px;
          margin-left: 20px;
          transform: translateY(-70px);
        }

        &.post-all a {
          background-color: var(--c-all);
        }

        &.post-outdoor a {
          background-color: var(--c-outdoor);
          &:after {
            border: 0px solid var(--c-outdoor-d);
          }
        }

        &.post-tools a {
          background-color: var(--c-tools);
          &:after {
            border: 0px solid var(--c-tools-d);
          }
        }

        &.post-graphic a {
          background-color: var(--c-artworks);
          &:after {
            border: 0px solid var(--c-artworks-d);
          }
        }

        &.post-maniac a {
          background-color: var(--c-maniac);
          &:after {
            border: 0px solid var(--c-maniac-d);
          }
        }

        &.post-3-6 a {
          background-color: var(--c-meme);

          &:after {
            border: 0px solid var(--c-meme-d);
          }
        }

        a {
          background-color: gray;
          display: block;
          width: 100%;
          height: 100%;
          position: relative;

          &:after {
            content: '';
            position: absolute;
            bottom: 0;
            right: 0;
            width: 0px;
            height: 0px;
            border: 0px solid #333;
            border-bottom-color: #fff !important;
            border-right-color: #fff !important;
            background-color: #fff !important;
            // overflow: hidden;
            transition: all 0.1s ease;
          }

          &:hover {
            transition: all 0.1s ease-in;
            margin-top: -5px;

            &:after {
              transform: scale(1.1);
              border-width: 5px;
              filter: blur(0.2px);
              @media screen and (-webkit-min-device-pixel-ratio:0) {
                filter: blur(0px) !important;
              }
            }
          }
        }
      }
    }
  }
}
