.post-list {
  padding: 0 20px;

  @include media-up(md){
    padding: 0 10px;
  }

  a {
    text-decoration: none;
    color: $color-black;
    font-size: 100vw;

    @include media-up(md){
      font-size: 1050px;
    }
  }
}

.post-list-col {
  margin-bottom: 30px;
  position: relative;
  // rotate: -0.25deg;

  @include media-up(md){
    margin-bottom: 40px;

    span:after {
      content: '';
      position: absolute;
      top: 0;
      left: -50vw;
      width: 150vw;
      display: inline-block;
      z-index: -1;
      height: 100%;
      opacity: 0;
      transition: all 0.4s ease-in;
    }

    &:hover {
      span:after {
        transition: all 0.01s ease-in;
      }
    }

    &.cat-outdoor:hover {
      span:after {
        content: '';
        position: absolute;
        top: 0;
        left: -50vw;
        width: 150vw;
        display: inline-block;
        z-index: -1;
        height: 100%;
        opacity: 1;
        background-color: var(--c-outdoor);
      }
    }

    &.cat-tools:hover {
      span:after {
        content: '';
        position: absolute;
        top: 0;
        left: -50vw;
        width: 150vw;
        display: inline-block;
        z-index: -1;
        height: 100%;
        opacity: 1;
        background-color: var(--c-tools);
      }
    }

    &.cat-graphic:hover {
      span:after {
        content: '';
        position: absolute;
        top: 0;
        left: -50vw;
        width: 150vw;
        display: inline-block;
        z-index: -1;
        height: 100%;
        opacity: 1;
        background-color: var(--c-artworks);
      }
    }

    &.cat-maniac:hover {
      span:after {
        content: '';
        position: absolute;
        top: 0;
        left: -50vw;
        width: 150vw;
        display: inline-block;
        z-index: -1;
        height: 100%;
        opacity: 1;
        background-color: var(--c-maniac);
      }
    }

    &.cat-3-6:hover {
      span:after {
        content: '';
        position: absolute;
        top: 0;
        left: -50vw;
        width: 150vw;
        display: inline-block;
        z-index: -1;
        height: 100%;
        opacity: 1;
        background-color: var(--c-meme);
      }
    }
  }


  .post-list-col__header {
    display: flex;
    font-family: $font-body;
    font-size: 12px;
    z-index: 1;
    font-weight: 600;
    margin-bottom: 5px;

    @include media-up(md){
      letter-spacing: 0.5px;
    }
  }

  .post-list-col__title {
    font-size: 35px;
    font-weight: 700;
    letter-spacing: -0.02em;
    font-feature-settings: "palt" 1 !important;
    line-height: 1.2;

    @include media-up(md) {
      font-size: 55px;
      font-weight: 700;
      letter-spacing: -0.02em;
      line-height: 1.2;
    }

    span {
      line-height: auto;
      mix-blend-mode: multiply;
    }
  }

  $item-width_s: 85%;
  $item-width: 70%;

  [class^="c1"] {
  font-size: calc(($item-width_s / 3)) !important;
  line-height: 1;
  }

  [class^="c2"] {
  font-size: calc(($item-width_s / 3)) !important;
  line-height: 1;
  letter-spacing: -0.02em;
  }

  [class^="c3"] {
  font-size: calc(($item-width_s / 3)) !important;
  line-height: 1;
  }

  [class^="c4"] {
  font-size: calc(($item-width_s / 4)) !important;
  line-height: 1;
  }

  [class^="c5"] {
  font-size: calc(($item-width_s / 5)) !important;
  line-height: 1;
  }
  [class^="c6"] {
    font-size: calc(($item-width_s / 6)) !important;
    line-height: 1;
  }

  [class^="c7"] {
    font-size: calc(($item-width_s / 7)) !important;
    line-height: 1;
  }

  [class^="c8"] {
    font-size: calc(($item-width_s / 8)) !important;
    line-height: 1;
  }

  [class^="c9"] {
    font-size: calc(($item-width_s / 9)) !important;
    line-height: 1;
  }

  [class^="c10"] {
    font-size: 35px !important;
    line-height: 1.4 !important;
  }

  @include media-up(md){

    [class^="c1"] {
      font-size: calc(($item-width / 3)) !important;
      line-height: 1;
    }

    [class^="c2"] {
      font-size: calc(($item-width / 5)) !important;
      line-height: 1;
      letter-spacing: -0.02em;
    }

    [class^="c3"] {
      font-size: calc(($item-width / 5)) !important;
      line-height: 1;
    }

    [class^="c4"] {
      font-size: calc(($item-width / 6)) !important;
      line-height: 1;
    }

    [class^="c5"] {
      font-size: calc(($item-width / 5)) !important;
      line-height: 1;
    }

    [class^="c6"] {
      font-size: calc(($item-width / 7)) !important;
      line-height: 1;
    }

    [class^="c7"] {
      font-size: calc(($item-width / 8)) !important;
      line-height: 1;
    }

    [class^="c8"] {
      font-size: calc(($item-width / 8)) !important;
      line-height: 1;
    }

    [class^="c9"] {
      font-size: calc(($item-width / 9)) !important;
      line-height: 1;
    }

    [class^="c10"] {
      font-size: 60px !important;
      line-height: 1.4 !important;
    }

    // [class^="o"] {
    //   font-size: 36px;
    //   width: 70vw;
    //   line-height: 1.4;
    //   font-weight: 700;
    //   // letter-spacing: -0.02em;
    // }
    // [class^="o11"] {
    //   font-size: calc(($item-width / 11))  !important;
    //   width: 100%;
    //   line-height: 1;
    // }
    // [class^="o12"] {
    //   font-size: calc(($item-width / 12))  !important;
    //   width: 100%;
    //   line-height: 1;
    // }
    // [class^="o13"] {
    //   font-size: calc(($item-width / 13))  !important;
    //   width: 100%;
    //   line-height: 1;
    // }
    // [class^="o14"] {
    //   font-size: calc(($item-width / 14))  !important;
    //   width: 100%;
    //   line-height: 1;
    // }
  }

}

.post-list-pager {
  padding: 20px 10px;
  font-size: 14px;
  text-align: center;
  font-family: $font-serif;
  margin-top: 35px;
  padding-bottom: 20px;

  @include media-up(md){
    font-size: 20px;
    padding: 40px 10px;
    padding-bottom: 80px;
  }

  .pager {
    display: inline-block;

    a {
      color: $color-black;
      &:hover {
        transform: scale(1.5);
      }
    }

    .page-numbers {
      margin: 10px 5px;
      padding: 5px;
      display: inline-block;

      @include media-up(md) {
        margin: 20px 20px 10px;
        padding: 10px 5px;
        display: inline-block;
      }

      &.current {
        border-bottom: 2px solid #000;

      }
    }

    .next,
    .prev {
      font-family: FontAwesome !important;
    }
  }

}



.blog-entry {
  padding: 0 4vw;

  @include media-up(md){
    padding: 20px;
  }

  .blog-entry__title {
    font-size: 35px;
    font-weight: 700;
    letter-spacing: -1.8px;
    line-height: 1.4;
    padding: 0 0px;

    @include media-up(md){
      font-size: 45px;
      font-weight: 700;
      letter-spacing: -1px;
      line-height: 1.4;
      padding: 0 40px;
    }
  }

  .blog-entry__subtitle {
    font-family: $font-body;
    display: flex;
    font-weight: 600;
    justify-content: flex-end;
    font-size: 11px;
    margin-bottom: 10px;
    padding-right: 10px;

    @include media-up(md){
      margin-bottom: 30px;
      padding-right: 40px;
    }

    a {
      color: $color-black;
      text-decoration: none;
    }

    .blog-entry__category {
      margin-left: 10px;
    }
  }

  .blog-entry__body {
    margin-top: 30px;
    font-weight: 600;

    @include media-up(md){
      margin-top: 50px;
    }

    p {
      font-size: 16.5px;
      // letter-spacing: -0.02em;
      font-feature-settings: "palt" 1;
      padding: 0;
      line-height: 32px;
      // line-height: 36px;
      text-justify: inter-ideograph;
      word-break: break-word;
      word-wrap: break-word;
      margin-bottom: 20px;


      @include media-up(md) {
        padding: 0 40px;
        line-height: 44px;
        font-size: 20px;
        margin-bottom: 20px;
        text-justify: inter-ideograph;
      }
    }

    a {
      color: $color-black;
      text-decoration-line: underline;
      text-decoration-style: wavy;
      text-underline-offset: 5px;
    }

    cite {
      text-align: right;
      display: block;
      padding-bottom: 10px;
      font-family: $font-body;
    }

    figure {
      line-height: 33px;
      margin-bottom: 20px;
      padding: 0;

      @include media-up(md) {
        padding: 0 40px;
        margin-bottom: 30px;
      }

      &.youtube {
        aspect-ratio: 16 / 9;

        iframe {
          width: 100% !important;
          height: 100% !important;
        }
      }
    }

    figcaption {
      font-size: 12px;
      margin-top: 10px;
      margin-bottom: 30px;
      font-family: $font-body;
      font-weight: 400;
      line-height: 1.6;
      text-align: center;
      color: $color-black;

      @include media-up(md) {
        font-size: 14px;
        margin-top: 15px;
        line-height: 1.8;
        margin-bottom: 50px;
      }
    }

    img {
      width: calc(100% + 8vw) !important;
      max-width: calc(100% + 8vw) !important;
      height: auto;
      margin: 0 -4vw 0px -4vw;

      @include media-up(md) {
        width: calc(100% + 80px) !important;
        max-width: calc(100% + 80px) !important;
        height: auto;
        margin: 0 -40px 0px -40px !important;
      }
    }

    a > img {
      // padding-top: 40px;
      // padding-bottom: 40px;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: $font-serif;
      color: $color-black;
      font-weight: 500;
      margin-bottom: 15px;
      margin-top: 40px;
      color: $color-black;
      // letter-spacing: 0.1em;
    }

    h1, h2 {
      font-size: 22px;
      padding: 0 5px;
      margin: 30px 0 20px;
      border-bottom: 2px solid #000;
      border-top: 2px solid #000;
      display: inline-block;
      font-weight: 700;
      letter-spacing: -0.02em;

      @include media-up(md) {
        font-size: 30px;
        margin: 40px 40px 30px 40px;
        color: $color-black;
        padding: 5px 10px;
      }

      span {
        color: $color-black;
      }
    }

    h1 + h2 {
      margin-top: 20px;
      display: block;
      width: fit-content;
    }

    h3 {
      font-size: 20px;
      padding: 0;
      font-weight: 700;
      letter-spacing: -0.02em;


      @include media-up(md) {
        font-size: 26px;
        padding: 0 40px;
      }
    }

    h4 {
      font-size: 18px;
      font-weight: bold;
      padding: 0;
      padding-left: 10px;
      font-weight: 700;
      letter-spacing: -0.02em;
      border-left: 3px solid #000;

      @include media-up(md) {
        font-size: 22px;
        border-left: 0 solid #000;
        padding: 0 40px;
      }
    }

    h5, h6 {
      font-size: 18px;
      font-weight: bold;
      padding: 0;
      font-weight: 700;
      letter-spacing: -0.02em;

      @include media-up(md) {
        font-size: 22px;
        padding: 0 40px;
      }
    }

    strong {
      padding: 3px;
      vertical-align: top;
      margin: 0px 3px;
    }

    hr {
      display: block;
      width: 100%;
      height: 1px;
      background: #dcdcdc;
      margin: 30px auto;
      border: none;

      @include media-up(md) {
        margin: 50px auto;
      }
    }

    blockquote {
      margin-left: -4vw;
      margin-right: -4vw;
      background-color: #f9f9f9;
      border-left: 4px solid #eaeaea;
      padding: 20px 35px;
      margin-bottom: 30px;
      font-style: italic;

      @include media-up(md) {
        margin-left: 0;
        margin-right: 0;
        padding: 40px 70px;
        background-color: #f8f8f8;
      }

      p {
        margin-bottom: 0;
        padding: 10px 0;
      }

      img {
        margin: 0 !important;
        width: auto !important;
        max-width: auto !important;
      }

      cite {
        font-family: $font-serif;
      }
    }

    code,
    pre {
      font-family: 'Source Code Pro', Consolas, 'Zen Kaku Gothic New', Menlo, Monaco, monospace;
    }

    code {
      font-size: 14px;
      font-family: 'Source Code Pro', Consolas, 'Zen Kaku Gothic New', Menlo, Monaco, monospace;
      margin: 0px 3px;
      padding: 2px 4px;
      border-radius: 4px;
      background-color: #d6d6d6;
      color: #0F0F0F;

      @include media-up(md) {
        padding: 5px 8px 5px;
        font-size: 16px;
        font-weight: 500;
      }
    }

    pre {
      width: calc(100% + 8vw);
      margin: 1.3em -4vw;
      padding: 20px 35px;
      border: none;
      background-color: #3a3a3a;
      color: #fff !important;
      font-size: 12px;
      font-family: 'Source Code Pro', Consolas, Menlo, Monaco, monospace;
      line-height: 1.52;
      white-space: pre;
      overflow: auto;

      @include media-up(md) {
        width: 100%;
        margin: 1.3em 0;
        padding: 40px 70px;
        font-size: 14px;
      }

      code {
        padding: 0 !important;
        background: none !important;
        color: $color-white;
      }
    }

    code.hljs {
      background-color: #3a3a3a;
    }

    ul,
    ol {
      list-style-position: inside;
      padding: 0 20px 20px;

      @include media-up(md) {
        padding: 0 60px 20px;
      }
    }

    ol {
      counter-reset: listnum;
    }
    ol li::before{
      counter-increment: listnum; /* counter-resetと同じ文字列 */
      content: counter(listnum) ". "; /* カウントした数に応じて番号を表示 */
    }

    ol li ul {
      li {
        &::marker {
          // margin-right: -3px;
        }
      }
      li::before {
        counter-increment: none !important;
        content: none !important;
      }
    }

    ul {
      list-style-type: disc;
    }

    li {
      font-size: 16.5px;
      // letter-spacing: 0.1em;
      line-height: 1.6;
      margin-bottom: 15px;
      text-indent: -1em;
      padding-left: 1em;
      text-align: justify;
      text-justify: inter-ideograph;

      @include media-up(md) {
        text-align: left;
        font-size: 20px;
        margin-bottom: 10px;
        line-height: 40px;
        text-justify: inter-ideograph;
      }

      >ul,
      >ul>li {
        margin-bottom: 0 !important;
        padding-bottom: 0;
      }

      ul {
        list-style-type: circle;
        padding-right: 0;
        padding-left: 2.5%;

        ul {
          list-style-type: disc;
          padding-right: 0;
          padding-left: 2.5%;

          ul {
            padding-right: 0;
            list-style-type: circle;
            padding-left: 2.5%;
          }
        }
      }
    }

    sup {
      font-size: 60%; //お好きなサイズに
      vertical-align: top;
      position: relative;
      top: -0.1em; //お好きな位置に

      @include media-up(md){
        top: -0.3em;
      }

      &::before {
        content:'[';
      }
      &::after {
        content: ']';
      }

      a {
        vertical-align: top;
      }
    }

    .footnotes, .wp-block-footnotes {
      margin-top: 20px;
      li {
        font-size: 14px;
        line-height: 1.8;
        margin-bottom: 5px;
      }
    }
  }
}

#blog .blog-entry .blog-entry__body .amazlet-box img {
  width: auto !important;
  max-width: 100% !important;
  margin: 0 !important;
  padding-left: 10px;
}

.amazlet-box,
.amachazl-box {
  padding: 20px;
}

.nivo-lightbox-theme-default.nivo-lightbox-overlay {
  background: rgba(0, 0, 0, 0.0) !important;
}


.blog-entry-nav-wrap {
  // border-top: solid 1px #eee;
  padding-top: 35px;
  text-align: center;

  .blog-entry-nav {
    display: inline-block;
    position: relative;
    width: 70%;
    min-height: 44px;
    padding-bottom: 35px;
    font-family: $font-serif;

    @include media-up(md) {
      width: 600px;
      padding-top: 70px;
      padding-bottom: 50px;
    }

    .blog-entry-nav__menu {
      position: absolute;
      display: inline-block;
      width: 100px;
      left: 0;
      right: 0;
      margin: auto;
      // padding-top: 6px;
      z-index: 0;
      font-weight: 500;
      font-size: 16px;
      @include media-up(md){
        font-size: 20px;
      }

      a {
        color: $color-black;
        transition: all 0.2s ease;

        &:hover {
          opacity: 0.5;
        }
      }
    }

    .blog-entry-nav__prev {
      float: left;
      font-size: 16px;
      z-index: 100;
      @include media-up(md){
        font-size: 20px;
      }

      a {
        color: $color-black;
        transition: all 0.2s ease;
        padding-left: 10px;
        display: block;

        &:hover {
          padding-left: 0px;
          padding-right: 10px;
        }
      }
    }

    .blog-entry-nav__next {
      float: right;
      font-size: 16px;
      z-index: 100;
      @include media-up(md){
        font-size: 20px;
      }

      a {
        color: $color-black;
        display: block;
        transition: all 0.2s ease;
        padding-right: 10px;

        &:hover {
          padding-right: 0px;
          padding-left: 10px;
        }
      }
    }
  }
}
